import React from "react";
import { graphql } from "gatsby";

import { BlogIndex } from "../components";

import withLayout from "../hoc/withLayout/withLayout";

interface BlogProps {
  data: {
    allGhostPost: Record<string, any>;
  };
}

const Blog = ({ data }: BlogProps) => {
  const edges = data.allGhostPost.edges;

  const posts = edges.map(edge => edge.node);

  return <BlogIndex posts={posts} />;
};

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { ne: "draft" } } } }
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;

export default withLayout(Blog);
